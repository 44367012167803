import useColoringBookStore from '../stores/ColoringBookStore';
import { useEffect } from 'react';
import { Grid, GridItem, Image, Text } from '@chakra-ui/react';

export const ColoringBookList = () => {
  const { books, fetchBooks, setCurrentBook } = useColoringBookStore();

  useEffect(() => {
    fetchBooks();
  }, [fetchBooks]);
  return (
    <div>
      {books?.length > 0 ? <></> : <h1>No Books</h1>}
      <Grid templateColumns="repeat(1, 1fr)" gap={6} w={'100%'}>
        {books?.map((book, index) => (
          <GridItem
            key={index}
            w="150px"
            h="200px"
            bg="gray.100"
            _hover={{ cursor: 'pointer' }}
            onClick={() => setCurrentBook(book)}
          >
            <Image
              src={`${process.env.REACT_APP_API_URL}/image/${book.id}?type=book`}
              alt={`${book.title} cover image`}
              boxSize="100%"
              objectFit="cover"
            />
            <Text fontSize={'medium'}>{book.title}</Text>
          </GridItem>
        ))}
      </Grid>
    </div>
  );
};
