import React from 'react';
import { Box, Heading, Flex, Divider, Stack } from '@chakra-ui/react';

interface SectionHeaderProps {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
  [x: string]: any; // Allow additional props
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  actions,
  ...rest
}) => {
  return (
    <Box mb={4} {...rest}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="lg">
            {title}
          </Heading>
          {/*{subtitle && <Text mt={2}>{subtitle}</Text>}*/}
        </Box>
        {actions && (
          <Stack direction="row" spacing={4}>
            {actions}
          </Stack>
        )}
      </Flex>
      <Divider mt={4} />
    </Box>
  );
};

export default SectionHeader;
