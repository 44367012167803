import { VStack } from '@chakra-ui/react';
import CreateColoringBookPageButton from './buttons/CreateColoringBookPageButton';
import { ColoringBookGrid } from './ColorginBookGrid';
import * as React from 'react';
import SectionHeader from './SectionHeader';
import useColoringBookStore from '../stores/ColoringBookStore';

export const ColoringBookDetails = () => {
  const { currentBook } = useColoringBookStore();
  return (
    <VStack
      width={'100%'}
      h={'calc(100vh - 48px)'}
      gap={0}
      verticalAlign={'top'}
      p={4}
    >
      <SectionHeader
        width={'100%'}
        title={currentBook?.title}
        actions={
          <>
            <CreateColoringBookPageButton />
          </>
        }
      />
      <ColoringBookGrid />
    </VStack>
  );
};
