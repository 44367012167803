import { Button, useDisclosure, Flex } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/all';
import { CreateColoringBookModal } from '../modals/CreateColoringBookModal';

const CreateColoringBookButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex p={0} m={0}>
      <Button w={'flex'} m={0} variant={'outline'} size={'sm'} onClick={onOpen}>
        <BiPlus />
      </Button>
      <CreateColoringBookModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default CreateColoringBookButton;
