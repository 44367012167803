import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import useColoringBookStore from '../../stores/ColoringBookStore';

interface CreateColoringBookPageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateColoringBookPageModal = ({
  isOpen,
  onClose,
}: CreateColoringBookPageModalProps) => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const { currentImage, createImage, createPage } = useColoringBookStore();

  const handleGenerate = async () => {
    setLoading(true);
    await createImage({ prompt });
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    //TODO: disable button
    await createPage({
      image_url: currentImage?.data[0].url,
      prompt: prompt,
      revised_prompt: currentImage?.data[0].revised_prompt,
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a New Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="prompt" isRequired>
            <FormLabel>Prompt</FormLabel>
            <Textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter description for your page"
            />
          </FormControl>

          {loading && <Spinner />}

          {currentImage && (
            <Image src={currentImage.data[0].url} alt="Coloring Page" />
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleGenerate}>
            Generate
          </Button>
          {currentImage && (
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
