import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import useColoringBookStore from '../stores/ColoringBookStore';

const AuthContext = createContext({ user: null });

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const { setCurrentSession } = useColoringBookStore();

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      const { session } = data;
      // const { session } = data;
      console.log('session', session);
      if (session) {
        setUser(session?.user ?? null);
        setCurrentSession(session ?? null);
      } else {
        navigate('/login');
      }
    };
    getSession();

    // Listen for changes in authentication state
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('onAuthStateChange', event, session);

        if (!session?.user) {
          return navigate('/login');
        }

        if (event === 'INITIAL_SESSION') {
          // handle initial session
        } else if (event === 'SIGNED_IN') {
          // handle sign in event
        } else if (event === 'SIGNED_OUT') {
          // handle sign out event
          return navigate('/login');
        } else if (event === 'PASSWORD_RECOVERY') {
          // handle password recovery event
        } else if (event === 'TOKEN_REFRESHED') {
          // handle token refreshed event
        } else if (event === 'USER_UPDATED') {
          // handle user updated event
        }
        setUser(session?.user ?? null);
        setCurrentSession(session ?? null);
      },
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []); // eslint-disable-line

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
