import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import useColoringBookStore from '../../stores/ColoringBookStore';

interface CreateColoringBookModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateColoringBookModal = ({
  isOpen,
  onClose,
}: CreateColoringBookModalProps) => {
  const [title, setTitle] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const { createBook } = useColoringBookStore();

  const handleSubmit = async () => {
    await createBook({ title, cover_image: coverImage });
    setTitle('');
    setCoverImage('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a New Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter book title"
            />
          </FormControl>

          <FormControl id="coverImage" mt={4}>
            <FormLabel>Cover Image URL</FormLabel>
            <Input
              value={coverImage}
              onChange={(e) => setCoverImage(e.target.value)}
              placeholder="Enter cover image URL"
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
