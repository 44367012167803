import { rgba } from 'color2k';

export const semanticTokens = {
  colors: {
    electricPurple: {
      default: '#4C2E90',
    },
    lagunaTeal: {
      default: '#1EB3AB',
    },
    softGray: {
      default: '#F3F3F3',
    },
    midnightPurple: {
      default: '#131629',
    },
    chatBackground: {
      default: '#FFFFFF',
      _dark: rgba(26, 32, 44, 1),
    },
    chatMessageBackground: {
      default: '#F3F3F3',
      _dark: 'black',
    },
    featureTextColor: {
      default: 'electricPurple',
      _dark: 'white',
    },
    primaryText: {
      default: 'gray.100',
      _dark: 'whiteAlpha.700',
    },
    secondaryText: {
      default: 'gray.400',
      _dark: 'whiteAlpha.500',
    },
  },
};
