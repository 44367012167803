import * as React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import Header from './components/layout/Header';
import { ColoringBookList } from './components/ColoringBookList';
import CreateColoringBookButton from './components/buttons/CreateColoringBookButton';
import useColoringBookStore from './stores/ColoringBookStore';
import { ColoringBookDetails } from './components/ColoringBookDetails';
import SectionHeader from './components/SectionHeader';

export const App = () => {
  const { currentBook } = useColoringBookStore();
  return (
    <Box textAlign="center" fontSize="xl">
      <Header />
      <HStack minH="calc(100vh - 64px)" w={'100%'}>
        <VStack
          h={'calc(100vh - 48px)'}
          w={'300px'}
          gap={4}
          p={4}
          verticalAlign={'top'}
        >
          <SectionHeader
            width={'100%'}
            title={`My Books`}
            actions={<CreateColoringBookButton />}
          />
          <ColoringBookList />
        </VStack>
        {currentBook && <ColoringBookDetails />}
      </HStack>
    </Box>
  );
};
