import React from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  useColorMode,
} from '@chakra-ui/react';
import { FaSun, FaMoon } from 'react-icons/fa';

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box bg={'midnightPurple'} px={4} color={'primaryText'}>
      <Flex h={12} alignItems="center" justifyContent="space-between">
        <Heading size="sm" textTransform={'uppercase'}>
          Custom Coloring Books
        </Heading>
        <HStack spacing={8} alignItems="center">
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {/*<Link href="/">Home</Link>*/}
            {/*<Link href="/about">About</Link>*/}
            {/*<Link href="/contact">Contact</Link>*/}
          </HStack>
          <IconButton
            size="md"
            fontSize="lg"
            aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
            variant="ghost"
            color="current"
            ml={2}
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default Header;
