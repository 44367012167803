import create from 'zustand';
import supabase from '../supabaseClient';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`; // Replace with your actual API URL
console.log('apiUrl:', apiUrl);

type ColoringBook = {
  id: string;
  created_at: string;
  updated_at: string;
  cover_image: string;
  title: string;
  description: string;
  pages: ColoringBookPage[];
};
type ColoringBookPage = {
  id: string;
  created_at: string;
  updated_at: string;
  image_url: string;
  book_id: string;
};
type ColoringBookImage = {
  created: number;
  data: [
    {
      revised_prompt: string;
      url: string;
    },
  ];
};

type State = {
  books: ColoringBook[];
  currentBook: ColoringBook | null;
  currentImage: ColoringBookImage | null;
  currentSession: any;
};

type Actions = {
  setCurrentBook: (book: ColoringBook) => void;
  createBook: (book: Partial<ColoringBook>) => Promise<void>;
  fetchBooks: () => Promise<void>;
  createImage: (image: any) => Promise<void>;
  createPage: (page: any) => Promise<void>;
  setCurrentSession: (user: any) => void;
};

type ColoringBookStore = State & Actions;

const useColoringBookStore = create<ColoringBookStore>((set, get) => ({
  books: [],
  pages: [],
  currentBook: null,
  currentImage: null,
  currentSession: null,
  setCurrentSession: (session) => {
    set({ currentSession: session });
  },
  fetchBooks: async () => {
    try {
      const { data: sessionData } = await supabase.auth.getSession();
      const { session } = sessionData;
      console.log('fetchBooks session:', session);
      const token = session?.access_token;
      console.log('fetchBooks token:', token);
      const response = await fetch(`${apiUrl}/books/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data.success === false) {
        console.error('Error fetching books:', data.error);
        return;
      }

      set({ books: data.books });
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  },
  createBook: async (book) => {
    try {
      const response = await fetch(`${apiUrl}/books/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + get().currentSession?.access_token || '',
        },
        body: JSON.stringify(book),
      });
      const data = await response.json();

      if (data.success === false) {
        console.error('Error creating book:', data.error);
        return;
      }

      await get().fetchBooks();
    } catch (error) {
      console.error('Error creating book:', error);
    }
  },
  setCurrentBook: (book) => {
    set({ currentBook: book });
  },
  createImage: async (image: any) => {
    try {
      const response = await fetch(`${apiUrl}/image/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + get().currentSession?.access_token || '',
        },
        body: JSON.stringify(image),
      });
      const data = await response.json();

      if (data.success === false) {
        console.error('Error creating image:', data.error);
        return;
      }

      set({ currentImage: data.image });
    } catch (error) {
      console.error('Error creating image:', error);
    }
  },
  createPage: async (page: any) => {
    try {
      const currentBook = get().currentBook;
      const response = await fetch(
        `${apiUrl}/books/${currentBook?.id}/pages/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + get().currentSession?.access_token || '',
          },
          body: JSON.stringify(page),
        },
      );
      const data = await response.json();

      if (data.success === false) {
        console.error('Error creating page:', data.error);
        return;
      }

      //TODO: this is causing an error
      // currentBook?.pages.push(data.page);
      set({ currentBook });

      await get().fetchBooks();
    } catch (error) {
      console.error('Error creating page:', error);
    }
  },
}));

export default useColoringBookStore;
