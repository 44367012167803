import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import supabase from '../supabaseClient';
import { useNavigate } from 'react-router-dom'; // Make sure to import your Supabase client configuration

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    console.log('signIn', { data, error });
    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Success',
        description: 'Logged in successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Box
        maxW="md"
        w="full"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        rounded="lg"
        p={6}
      >
        <Stack spacing={4}>
          <Heading as="h1" size="lg" textAlign="center">
            Log in to your account
          </Heading>
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Stack spacing={10}>
            <Button
              bg="blue.400"
              color="white"
              _hover={{ bg: 'blue.500' }}
              onClick={handleSignIn}
            >
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default LoginPage;
