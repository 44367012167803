import { Grid, GridItem, Image } from '@chakra-ui/react';
import useColoringBookStore from '../stores/ColoringBookStore';

export const ColoringBookGrid = () => {
  const { currentBook } = useColoringBookStore();

  //TODO: add type here
  const handleClickImage = (page: any) => {
    console.log('Image clicked');
    window.open(
      `${process.env.REACT_APP_API_URL}/image/${page.id}?type=page`,
      '_blank',
    );
  };

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} w={'100%'} p={4}>
      {currentBook?.pages?.map((page, index) => (
        <GridItem
          key={index}
          w="150px"
          h="200px"
          bg="gray.100"
          _hover={{ cursor: 'pointer' }}
          onClick={() => handleClickImage(page)}
        >
          <Image
            src={`${process.env.REACT_APP_API_URL}/image/${page.id}?type=page`}
            alt={`Coloring page ${index + 1}`}
            boxSize="100%"
            objectFit="cover"
          />
        </GridItem>
      ))}

      {/*<GridItem*/}
      {/*  w="100%"*/}
      {/*  maxW={'150px'}*/}
      {/*  h="200px"*/}
      {/*  bg="gray.100"*/}
      {/*  _hover={{ cursor: 'pointer' }}*/}
      {/*>*/}
      {/*  <Text fontSize={'xl'}>Add New Page</Text>*/}
      {/*  <Center>*/}
      {/*    <IoIosAddCircleOutline color={'red'} />*/}
      {/*  </Center>*/}
      {/*</GridItem>*/}
    </Grid>
  );
};
