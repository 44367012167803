import { Button, useDisclosure } from '@chakra-ui/react';
import { CreateColoringBookPageModal } from '../modals/CreateColoringBookPageModal';

const CreateColoringBookPageButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant={'solid'} size={'sm'} onClick={onOpen}>
        Create New Page
      </Button>
      <CreateColoringBookPageModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CreateColoringBookPageButton;
